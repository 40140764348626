exports.components = {
  "component---src-pages-150-european-enterprise-sales-teams-js": () => import("./../../../src/pages/150-european-enterprise-sales-teams.js" /* webpackChunkName: "component---src-pages-150-european-enterprise-sales-teams-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-customer-success-js": () => import("./../../../src/pages/customer-success.js" /* webpackChunkName: "component---src-pages-customer-success-js" */),
  "component---src-pages-data-points-js": () => import("./../../../src/pages/data-points.js" /* webpackChunkName: "component---src-pages-data-points-js" */),
  "component---src-pages-datasets-45-uk-b-2-b-saas-js": () => import("./../../../src/pages/datasets/45-uk-b2b-saas.js" /* webpackChunkName: "component---src-pages-datasets-45-uk-b-2-b-saas-js" */),
  "component---src-pages-datasets-global-2000-ai-js": () => import("./../../../src/pages/datasets/global-2000-ai.js" /* webpackChunkName: "component---src-pages-datasets-global-2000-ai-js" */),
  "component---src-pages-datasets-global-2000-cyber-security-js": () => import("./../../../src/pages/datasets/global-2000-cyber-security.js" /* webpackChunkName: "component---src-pages-datasets-global-2000-cyber-security-js" */),
  "component---src-pages-datasets-global-2000-digital-transformation-js": () => import("./../../../src/pages/datasets/global-2000-digital-transformation.js" /* webpackChunkName: "component---src-pages-datasets-global-2000-digital-transformation-js" */),
  "component---src-pages-datasets-global-2000-sustainability-js": () => import("./../../../src/pages/datasets/global-2000-sustainability.js" /* webpackChunkName: "component---src-pages-datasets-global-2000-sustainability-js" */),
  "component---src-pages-datasets-us-marketing-channels-analysis-js": () => import("./../../../src/pages/datasets/us-marketing-channels-analysis.js" /* webpackChunkName: "component---src-pages-datasets-us-marketing-channels-analysis-js" */),
  "component---src-pages-generative-insights-js": () => import("./../../../src/pages/generative-insights.js" /* webpackChunkName: "component---src-pages-generative-insights-js" */),
  "component---src-pages-gocardless-js": () => import("./../../../src/pages/gocardless.js" /* webpackChunkName: "component---src-pages-gocardless-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-markdown-remark-frontmatter-category-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-category-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-revenue-operations-js": () => import("./../../../src/pages/revenue-operations.js" /* webpackChunkName: "component---src-pages-revenue-operations-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-summer-offer-terms-js": () => import("./../../../src/pages/summer-offer-terms.js" /* webpackChunkName: "component---src-pages-summer-offer-terms-js" */),
  "component---src-pages-top-50-plg-companies-in-europe-js": () => import("./../../../src/pages/top-50-PLG-companies-in-europe.js" /* webpackChunkName: "component---src-pages-top-50-plg-companies-in-europe-js" */),
  "component---src-pages-why-tactic-js": () => import("./../../../src/pages/why-tactic.js" /* webpackChunkName: "component---src-pages-why-tactic-js" */),
  "component---src-pages-win-with-tactic-js": () => import("./../../../src/pages/win-with-tactic.js" /* webpackChunkName: "component---src-pages-win-with-tactic-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/use-case.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

